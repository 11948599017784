var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-tooltip",
    _vm._b(
      {
        attrs: { color: _vm.color, top: "" },
        scopedSlots: _vm._u(
          [
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "div",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "mr-1",
                          staticStyle: { "border-radius": "50%" },
                        },
                        "div",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_vm._t("default")],
                    2
                  ),
                ]
              },
            },
          ],
          null,
          true
        ),
      },
      "v-tooltip",
      _vm.$attrs,
      false
    ),
    [_c("span", [_vm._v(_vm._s(_vm.tooltip))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }