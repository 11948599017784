var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { id: "Groups", fluid: "", tag: "section" } },
    [
      _c(
        "v-overlay",
        {
          attrs: { "z-index": "60" },
          model: {
            value: _vm.loaderlay,
            callback: function ($$v) {
              _vm.loaderlay = $$v
            },
            expression: "loaderlay",
          },
        },
        [
          _c(
            "v-progress-circular",
            {
              staticClass: "l2",
              attrs: { indeterminate: "", size: "100", color: "primary" },
            },
            [
              _c("v-progress-circular", {
                staticClass: "l3",
                attrs: { indeterminate: "", size: "75", color: "primary" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("KeyValueCard2", {
                attrs: {
                  loading: _vm.loading,
                  item: _vm.detail,
                  cols: _vm.filteredDetailCols,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "heading",
                      fn: function () {
                        return [
                          _c(
                            "CardHeading",
                            {
                              attrs: { color: "secondary" },
                              scopedSlots: _vm._u([
                                {
                                  key: "title",
                                  fn: function () {
                                    return [
                                      !_vm.detail.internal
                                        ? _c(
                                            "v-chip",
                                            { staticClass: "mr-1" },
                                            [_vm._v(" Casino ")]
                                          )
                                        : _vm._e(),
                                      _vm._v(
                                        " " + _vm._s(_vm.detailTitle) + " "
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            },
                            [
                              _c(
                                "span",
                                [
                                  _vm.detail.internal &&
                                  _vm._usrFlagsSome({
                                    key: "free_games.detail",
                                    val: _vm.permissions.EDIT,
                                  })
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "mx-1",
                                          attrs: {
                                            elevation: "1",
                                            fab: "",
                                            small: "",
                                            color: `primary`,
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.editDialog = true
                                            },
                                          },
                                        },
                                        [_c("v-icon", [_vm._v("mdi-pencil")])],
                                        1
                                      )
                                    : _vm._e(),
                                  !_vm.detail.is_removed
                                    ? _c(
                                        "v-dialog",
                                        {
                                          attrs: { "max-width": "500px" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({
                                                  on: dialog,
                                                  attrs,
                                                }) {
                                                  return [
                                                    _vm._usrFlagsSome({
                                                      key: "free_games.detail",
                                                      val: _vm.permissions
                                                        .DELETE,
                                                    }) && !_vm.detail.is_removed
                                                      ? _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              color:
                                                                "error darken-2",
                                                              bottom: "",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "activator",
                                                                  fn: function ({
                                                                    on: tooltip,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "span",
                                                                        _vm._g(
                                                                          {},
                                                                          tooltip
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-btn",
                                                                            _vm._g(
                                                                              _vm._b(
                                                                                {
                                                                                  staticClass:
                                                                                    "mx-1",
                                                                                  attrs:
                                                                                    {
                                                                                      elevation:
                                                                                        "1",
                                                                                      fab: "",
                                                                                      small:
                                                                                        "",
                                                                                      color:
                                                                                        "error",
                                                                                    },
                                                                                },
                                                                                "v-btn",
                                                                                attrs,
                                                                                false
                                                                              ),
                                                                              dialog
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                [
                                                                                  _vm._v(
                                                                                    "mdi-delete"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v("Delete"),
                                                            ]),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            3092872486
                                          ),
                                          model: {
                                            value: _vm.dialogDisable,
                                            callback: function ($$v) {
                                              _vm.dialogDisable = $$v
                                            },
                                            expression: "dialogDisable",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-card",
                                            [
                                              _c(
                                                "v-toolbar",
                                                {
                                                  staticClass:
                                                    "red d-flex white--text font-weight-bold",
                                                },
                                                [_vm._v(" Delete ")]
                                              ),
                                              _c("v-card-text", [
                                                _vm._v(
                                                  " Are you sure you want to delete this Offer ? "
                                                ),
                                              ]),
                                              _c(
                                                "v-card-actions",
                                                {
                                                  staticClass:
                                                    "justify-space-between",
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: { text: "" },
                                                      on: {
                                                        click: _vm.closeDisable,
                                                      },
                                                    },
                                                    [_vm._v(" NO ")]
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        color: "error",
                                                        text: "",
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.handleDisableOffer,
                                                      },
                                                    },
                                                    [_vm._v(" YES ")]
                                                  ),
                                                  void 0,
                                                ],
                                                2
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.detail.is_removed
                                    ? _c(
                                        "v-dialog",
                                        {
                                          attrs: { "max-width": "500px" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({
                                                  on: dialog,
                                                  attrs,
                                                }) {
                                                  return [
                                                    _vm._usrFlagsSome({
                                                      key: "free_games.detail",
                                                      val: _vm.permissions
                                                        .DELETE,
                                                    }) && _vm.detail.is_removed
                                                      ? _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              color:
                                                                "primary darken-2",
                                                              bottom: "",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "activator",
                                                                  fn: function ({
                                                                    on: tooltip,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "span",
                                                                        _vm._g(
                                                                          {},
                                                                          tooltip
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-btn",
                                                                            _vm._g(
                                                                              _vm._b(
                                                                                {
                                                                                  staticClass:
                                                                                    "mx-1",
                                                                                  attrs:
                                                                                    {
                                                                                      elevation:
                                                                                        "1",
                                                                                      fab: "",
                                                                                      small:
                                                                                        "",
                                                                                      color:
                                                                                        "red darken-1",
                                                                                    },
                                                                                },
                                                                                "v-btn",
                                                                                attrs,
                                                                                false
                                                                              ),
                                                                              dialog
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  staticStyle:
                                                                                    {
                                                                                      "padding-right":
                                                                                        "2px",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " mdi-restore "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v("Restore"),
                                                            ]),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            412829878
                                          ),
                                          model: {
                                            value: _vm.dialogReactivate,
                                            callback: function ($$v) {
                                              _vm.dialogReactivate = $$v
                                            },
                                            expression: "dialogReactivate",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-card",
                                            [
                                              _c(
                                                "v-toolbar",
                                                {
                                                  staticClass:
                                                    "red d-flex white--text font-weight-bold",
                                                },
                                                [_vm._v(" Restore ")]
                                              ),
                                              _c("v-card-text", [
                                                _vm._v(
                                                  " Are you sure you want to restore this Offer? "
                                                ),
                                              ]),
                                              _c(
                                                "v-card-actions",
                                                {
                                                  staticClass:
                                                    "justify-space-between",
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: { text: "" },
                                                      on: {
                                                        click:
                                                          _vm.closeReactivate,
                                                      },
                                                    },
                                                    [_vm._v(" NO ")]
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        color: "error",
                                                        text: "",
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.handleReactivateOffer,
                                                      },
                                                    },
                                                    [_vm._v(" YES ")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: `item.gamble_enabled.value`,
                      fn: function ({ item }) {
                        return [
                          item.gamble_enabled
                            ? _c("v-icon", { attrs: { color: "success" } }, [
                                _vm._v(" mdi-check "),
                              ])
                            : _c("v-icon", { attrs: { color: "error" } }, [
                                _vm._v(" mdi-close "),
                              ]),
                        ]
                      },
                    },
                    {
                      key: `item.valid_from.value`,
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            " " + _vm._s(_vm._f("Date")(item.valid_from)) + " "
                          ),
                        ]
                      },
                    },
                    {
                      key: `item.spacer.label`,
                      fn: function () {
                        return [_vm._v(_vm._s(""))]
                      },
                      proxy: true,
                    },
                    {
                      key: `item.valid_to.value`,
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            " " + _vm._s(_vm._f("Date")(item.valid_to)) + " "
                          ),
                        ]
                      },
                    },
                    {
                      key: `item.status.value`,
                      fn: function ({ item }) {
                        return [
                          item.is_removed
                            ? _c(
                                "v-chip",
                                { attrs: { small: "", color: "error" } },
                                [_vm._v(" Closed ")]
                              )
                            : new Date(item.valid_from).getTime() > _vm.timeNow
                            ? _c(
                                "v-chip",
                                { attrs: { small: "", color: "warning" } },
                                [_vm._v(" Pre-Activation ")]
                              )
                            : new Date(item.valid_to).getTime() < _vm.timeNow
                            ? _c(
                                "v-chip",
                                { attrs: { small: "", color: "warning" } },
                                [_vm._v(" Expired ")]
                              )
                            : _c(
                                "v-chip",
                                { attrs: { small: "", color: "success" } },
                                [_vm._v(" Active ")]
                              ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          ),
          _vm.detail.internal && _vm.getEnvironment === "dev"
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("KeyValueCard2", {
                    attrs: {
                      loading: _vm.loadingRule,
                      item: _vm.detail.offer_rules,
                      cols: _vm.filteredRuleCols,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "nodData",
                          fn: function () {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.detail.is_removed
                                      ? "Cannot create rules for deleted offer."
                                      : "This offer doesn't have an assigned rule"
                                  ) +
                                  " "
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "heading",
                          fn: function () {
                            return [
                              _c(
                                "CardHeading",
                                {
                                  attrs: {
                                    title: "Offer Rule",
                                    color: "secondary",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-inline-flex" },
                                    [
                                      _vm.detail.offer_rules === null &&
                                      !_vm.detail.is_removed
                                        ? _c(
                                            "v-tooltip",
                                            {
                                              key: `${_vm.rule_valid_from}-tooltip`,
                                              attrs: {
                                                color: "warning darken-2",
                                                bottom: "",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({
                                                      on,
                                                      attrs,
                                                    }) {
                                                      return [
                                                        _vm._usrFlagsSome({
                                                          key: "free_games.detail",
                                                          val: _vm.permissions
                                                            .EDIT,
                                                        })
                                                          ? _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    staticClass:
                                                                      "mx-1",
                                                                    attrs: {
                                                                      elevation:
                                                                        "1",
                                                                      fab: "",
                                                                      small: "",
                                                                      color:
                                                                        "warning",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        _vm.openRuleEdit,
                                                                    },
                                                                  },
                                                                  "v-btn",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _vm.detail
                                                                  .offer_rules ===
                                                                null
                                                                  ? _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          " mdi-plus "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                2740980082
                                              ),
                                            },
                                            [_c("span", [_vm._v("Add a rule")])]
                                          )
                                        : [
                                            _vm._usrFlagsSome({
                                              key: "free_games.detail",
                                              val: _vm.permissions.EDIT,
                                            }) && !_vm.detail.is_removed
                                              ? _c(
                                                  "v-btn",
                                                  {
                                                    staticClass: "mx-1",
                                                    attrs: {
                                                      elevation: "1",
                                                      fab: "",
                                                      small: "",
                                                      color: "primary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.ruleEditDialog = true
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("mdi-pencil"),
                                                    ]),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                      _vm.detail.offer_rules
                                        ? _c(
                                            "v-dialog",
                                            {
                                              attrs: { "max-width": "500px" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({
                                                      on: dialog,
                                                      attrs,
                                                    }) {
                                                      return [
                                                        _vm.detail.offer_rules
                                                          ? _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "error darken-2",
                                                                  bottom: "",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "activator",
                                                                        fn: function ({
                                                                          on: tooltip,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "span",
                                                                              _vm._g(
                                                                                {},
                                                                                tooltip
                                                                              ),
                                                                              [
                                                                                _c(
                                                                                  "v-btn",
                                                                                  _vm._g(
                                                                                    _vm._b(
                                                                                      {
                                                                                        staticClass:
                                                                                          "mx-1",
                                                                                        attrs:
                                                                                          {
                                                                                            elevation:
                                                                                              "1",
                                                                                            fab: "",
                                                                                            small:
                                                                                              "",
                                                                                            color:
                                                                                              "error",
                                                                                          },
                                                                                      },
                                                                                      "v-btn",
                                                                                      attrs,
                                                                                      false
                                                                                    ),
                                                                                    dialog
                                                                                  ),
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "mdi-delete"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "Delete"
                                                                  ),
                                                                ]),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                1306781437
                                              ),
                                              model: {
                                                value: _vm.dialogDelete,
                                                callback: function ($$v) {
                                                  _vm.dialogDelete = $$v
                                                },
                                                expression: "dialogDelete",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-card",
                                                [
                                                  _c(
                                                    "v-toolbar",
                                                    {
                                                      staticClass:
                                                        "red d-flex white--text font-weight-bold",
                                                    },
                                                    [_vm._v(" Delete ")]
                                                  ),
                                                  _c("v-card-text", [
                                                    _vm._v(
                                                      " Are you sure you want to delete this Rule ? "
                                                    ),
                                                  ]),
                                                  _c(
                                                    "v-card-actions",
                                                    {
                                                      staticClass:
                                                        "justify-space-between",
                                                    },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: { text: "" },
                                                          on: {
                                                            click:
                                                              _vm.closeRemove,
                                                          },
                                                        },
                                                        [_vm._v(" NO ")]
                                                      ),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            color: "error",
                                                            text: "",
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.handleRemoveRule,
                                                          },
                                                        },
                                                        [_vm._v(" YES ")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: `item.mode.which_days.value`,
                          fn: function ({ item }) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(item.mode.which_days.join(", ")) +
                                  " "
                              ),
                            ]
                          },
                        },
                        {
                          key: `item.mode.type.value`,
                          fn: function ({ item }) {
                            return [
                              item.mode.type === "recurring"
                                ? _c("span", [_vm._v("Recurring")])
                                : item.mode.type === "one_time"
                                ? _c("span", [_vm._v("One time")])
                                : _vm._e(),
                            ]
                          },
                        },
                        {
                          key: `item.condition.action.value`,
                          fn: function ({ item }) {
                            return [
                              item.condition.action ===
                              _vm.OFFER_RULE_ACTIONS.ROUNDS
                                ? _c("span", [_vm._v(" Rounds Started ")])
                                : item.condition.action ===
                                  _vm.OFFER_RULE_ACTIONS.BET
                                ? _c("span", [_vm._v(" Bet ")])
                                : item.condition.action ===
                                  _vm.OFFER_RULE_ACTIONS.SESSIONS
                                ? _c("span", [_vm._v(" Sessions Opened ")])
                                : item.condition.action ===
                                  _vm.OFFER_RULE_ACTIONS.LOOSE
                                ? _c("span", [_vm._v(" Lost ")])
                                : _vm._e(),
                            ]
                          },
                        },
                        {
                          key: `item.condition.limit.label`,
                          fn: function ({ item }) {
                            return [
                              item.condition.action ===
                                _vm.OFFER_RULE_ACTIONS.BET ||
                              item.condition.action ===
                                _vm.OFFER_RULE_ACTIONS.LOOSE
                                ? _c("span", [_vm._v(" Amount ")])
                                : _c("span", [_vm._v("Count")]),
                            ]
                          },
                        },
                        {
                          key: `item.condition.limit.value`,
                          fn: function ({ item }) {
                            return [
                              item.condition.action ===
                                _vm.OFFER_RULE_ACTIONS.BET ||
                              item.condition.action ===
                                _vm.OFFER_RULE_ACTIONS.LOOSE
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(item.condition.limit) +
                                        " " +
                                        _vm._s(item.condition.currency) +
                                        " "
                                    ),
                                  ])
                                : _c("span", [
                                    _vm._v(_vm._s(item.condition.limit)),
                                  ]),
                            ]
                          },
                        },
                        {
                          key: `item.hide_from_player.value`,
                          fn: function ({ item }) {
                            return [
                              !item.hide_from_player
                                ? _c(
                                    "v-icon",
                                    { attrs: { color: "success" } },
                                    [_vm._v(" mdi-check ")]
                                  )
                                : _c("v-icon", { attrs: { color: "error" } }, [
                                    _vm._v(" mdi-close "),
                                  ]),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.detail.internal && _vm.getEnvironment === "dev"
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "v-card--material px-5 py-3" },
                    [
                      _c(
                        "CardHeading",
                        {
                          attrs: {
                            loadingTitle: "Loading Black/White List",
                            loading: _vm.loadingList,
                            title: _vm.listMode,
                            color: "secondary",
                          },
                        },
                        [
                          _c(
                            "span",
                            [
                              _vm._usrFlagsSome({
                                key: "free_games.detail",
                                val: _vm.permissions.EDIT,
                              })
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "mx-1",
                                      attrs: {
                                        elevation: "1",
                                        fab: "",
                                        small: "",
                                        color: `primary`,
                                        to: {
                                          name: "free-games-blackwhitelist",
                                          params: {
                                            breadcrumbs: {
                                              title: "List View",
                                            },
                                          },
                                        },
                                      },
                                    },
                                    [_c("v-icon", [_vm._v("mdi-eye")])],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "v-card-text",
                        [
                          _vm.listMode == "No List"
                            ? _c("span", [
                                _vm._v(
                                  " This offer doesn't have a permissions list "
                                ),
                              ])
                            : _c("v-data-table", {
                                staticClass: "elevation-1",
                                attrs: {
                                  headers: _vm.listHeaders,
                                  items: _vm.aggregatedData,
                                  loading: _vm.loadingList,
                                  dense: "",
                                  "hide-default-header": "",
                                  "footer-props": {
                                    "items-per-page-options": [6],
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    _vm._l(_vm.itemsPerRow, function (i) {
                                      return {
                                        key: `item.item${i}`,
                                        fn: function ({ item }) {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(item[`item${i}`]) +
                                                " "
                                            ),
                                          ]
                                        },
                                      }
                                    }),
                                  ],
                                  null,
                                  true
                                ),
                              }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c(
                "v-card",
                { staticClass: "v-card--material px-5 py-3" },
                [
                  _c(
                    "card-heading",
                    { attrs: { color: "secondary", title: "Games" } },
                    [
                      _vm.detail.internal &&
                      _vm._usrFlagsSome({
                        key: "free_games.detail",
                        val: _vm.permissions.EDIT,
                      })
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                elevation: "1",
                                fab: "",
                                small: "",
                                color: "primary",
                              },
                              on: { click: _vm.openAddGames },
                            },
                            [_c("v-icon", [_vm._v("mdi-pencil")])],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("v-data-table", {
                    staticClass: "elevation-0",
                    attrs: {
                      "must-sort": "",
                      "sort-by": "game_id",
                      "sort-desc": false,
                      headers: _vm.filteredGameHeaders,
                      items: _vm.gameList,
                      options: _vm.gameOptions,
                      loading: _vm.loading,
                      "footer-props": _vm.footerProps,
                    },
                    on: {
                      "update:options": function ($event) {
                        _vm.gameOptions = $event
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: `item.bet`,
                          fn: function ({ item }) {
                            return [
                              _vm._v(
                                " " + _vm._s(item.configuration.bet) + " "
                              ),
                            ]
                          },
                        },
                        {
                          key: `item.currency`,
                          fn: function ({ item }) {
                            return [
                              _vm._v(
                                " " + _vm._s(item.configuration.currency) + " "
                              ),
                            ]
                          },
                        },
                        {
                          key: `item.actions`,
                          fn: function ({ item }) {
                            return [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: {
                                    "v-if": _vm._usrFlagsSome({
                                      key: "free_games.detail",
                                      val: _vm.permissions.EDIT,
                                    }),
                                    bottom: "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c("v-hover", {
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({ hover }) {
                                                      return [
                                                        _c(
                                                          "span",
                                                          {
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                $event.preventDefault()
                                                                return _vm.removeGame(
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        hover
                                                                          ? "red"
                                                                          : undefined,
                                                                    },
                                                                  },
                                                                  "v-icon",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  " mdi-close "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [_c("span", [_vm._v("Remove game from offer")])]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c(
                "v-card",
                { staticClass: "v-card--material px-5 py-3" },
                [
                  _c(
                    "card-heading",
                    { attrs: { color: "secondary", title: "Players" } },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex flex-row" },
                        [
                          _c(
                            "SimpleTooltip",
                            { attrs: { tooltip: `Import players` } },
                            [
                              !_vm.loading &&
                              _vm.detail.internal &&
                              !_vm.detail.is_removed &&
                              _vm._usrFlagsSome({
                                key: "free_games.detail",
                                val: _vm.permissions.EDIT,
                              })
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        elevation: "1",
                                        fab: "",
                                        small: "",
                                        color: "primary",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.importDialog = true
                                        },
                                      },
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-arrow-up-bold-circle"),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "SimpleTooltip",
                            {
                              attrs: {
                                tooltip: `${
                                  _vm.players?.length === 0
                                    ? "No players to export"
                                    : "Export players"
                                }`,
                              },
                            },
                            [
                              !_vm.loading
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        disabled: _vm.players?.length == 0,
                                        elevation: "1",
                                        fab: "",
                                        small: "",
                                        color: "primary",
                                      },
                                      on: { click: _vm.onExportOpen },
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-arrow-down-bold-circle"),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "SimpleTooltip",
                            { attrs: { tooltip: `Add players` } },
                            [
                              !_vm.loading &&
                              _vm.detail.internal &&
                              !_vm.detail.is_removed &&
                              _vm._usrFlagsSome({
                                key: "free_games.detail",
                                val: _vm.permissions.EDIT,
                              })
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        elevation: "1",
                                        fab: "",
                                        small: "",
                                        color: "primary",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.addPlayersDialog = true
                                        },
                                      },
                                    },
                                    [_c("v-icon", [_vm._v("mdi-plus")])],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c("ImportDialog", {
                            attrs: {
                              parser: "checkedParser",
                              importDialog: _vm.importDialog,
                            },
                            on: {
                              submit: _vm.onImport,
                              "update:importDialog": function ($event) {
                                _vm.importDialog = $event
                              },
                              "update:import-dialog": function ($event) {
                                _vm.importDialog = $event
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "hint-body",
                                fn: function () {
                                  return [
                                    _c("div", { staticClass: "my-1" }, [
                                      _vm._v(
                                        " This import utilizes the following data format: "
                                      ),
                                    ]),
                                    _c("i", [_c("b", [_vm._v("Players.csv")])]),
                                    _c(
                                      "v-card",
                                      { staticClass: "mt-1" },
                                      [
                                        _c("v-card-text", [
                                          _c(
                                            "table",
                                            [
                                              _c("tr", [
                                                _c("td", [
                                                  _c("b", [
                                                    _vm._v("player_id"),
                                                  ]),
                                                ]),
                                              ]),
                                              _vm._l(10, function (i) {
                                                return _c("tr", { key: i }, [
                                                  _c("td", [
                                                    _c("b", [
                                                      _vm._v(_vm._s(i)),
                                                    ]),
                                                  ]),
                                                ])
                                              }),
                                              _c("tr", [
                                                _c("td", [_vm._v("...")]),
                                              ]),
                                            ],
                                            2
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c("i", [
                                      _vm._v(
                                        ' Line separated list of player IDs with "player_id" header '
                                      ),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("v-data-table", {
                    staticClass: "elevation-0",
                    attrs: {
                      "must-sort": "",
                      "sort-by": "game_id",
                      "sort-desc": false,
                      headers: _vm.filteredPlayerHeaders,
                      items: _vm.players,
                      "item-class": _vm.itemClass,
                      options: _vm.playerOptions,
                      loading: _vm.playersLoading,
                      "footer-props": {
                        "items-per-page-options": [5, 10, 15, 25, 50, 100],
                      },
                    },
                    on: {
                      "update:options": function ($event) {
                        _vm.playerOptions = $event
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        this._usrFlagsSome({
                          key: "player.detail",
                          val: _vm.permissions.READ,
                        })
                          ? {
                              key: `item.player_id`,
                              fn: function ({ item }) {
                                return [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "hoverTheme body-2 font-weight-bold primary--text pa-1",
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.toPlayer(item)
                                        },
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(item.player_id) + " ")]
                                  ),
                                ]
                              },
                            }
                          : null,
                        {
                          key: `item.is_removed`,
                          fn: function ({ item }) {
                            return [
                              item.is_removed
                                ? _c("v-icon", { attrs: { color: "error" } }, [
                                    _vm._v(" mdi-check "),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                        {
                          key: `item.actions`,
                          fn: function ({ item }) {
                            return [
                              !item.is_removed
                                ? _c(
                                    "v-tooltip",
                                    {
                                      attrs: {
                                        color: "primary darken-2",
                                        bottom: "",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "span",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.openAddRounds(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            attrs: {
                                                              color: "primary",
                                                            },
                                                          },
                                                          "v-icon",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [_vm._v(" mdi-plus ")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _c("span", [
                                        _vm._v("Add free rounds to player"),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              !item.is_removed
                                ? _c(
                                    "v-tooltip",
                                    {
                                      attrs: {
                                        color: "primary darken-2",
                                        bottom: "",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c("ConfirmDialog2", {
                                                  attrs: {
                                                    persistent: "",
                                                    confirmBtnText: "Delete",
                                                    cancelBtnText: "Back",
                                                    confirmBtnColor: "error",
                                                    titleClass: "error",
                                                    cancelBtnColor:
                                                      "kajot-text",
                                                    shouldShow: true,
                                                  },
                                                  on: {
                                                    confirm: function ($event) {
                                                      return _vm.removePlayer(
                                                        item
                                                      )
                                                    },
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "body",
                                                        fn: function () {
                                                          return [
                                                            _vm._v(
                                                              " This player will be permanently deleted from the offer. "
                                                            ),
                                                            _c("br"),
                                                            _c("b", [
                                                              _vm._v(
                                                                "Do you want to proceed?"
                                                              ),
                                                            ]),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                      {
                                                        key: "default",
                                                        fn: function ({
                                                          showConfirm,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "span",
                                                              {
                                                                on: {
                                                                  click:
                                                                    showConfirm,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  _vm._g(
                                                                    _vm._b(
                                                                      {
                                                                        attrs: {
                                                                          color:
                                                                            "primary",
                                                                        },
                                                                      },
                                                                      "v-icon",
                                                                      attrs,
                                                                      false
                                                                    ),
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      " mdi-delete "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _c("span", [
                                        _vm._v("Remove player from offer"),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "500px" },
              on: {
                "click:outside": _vm.exportClose,
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "esc", 27, $event.key, [
                      "Esc",
                      "Escape",
                    ])
                  )
                    return null
                  return _vm.exportClose.apply(null, arguments)
                },
              },
              model: {
                value: _vm.exportDialog,
                callback: function ($$v) {
                  _vm.exportDialog = $$v
                },
                expression: "exportDialog",
              },
            },
            [
              _c("ExportDialog", {
                ref: "playerExport",
                attrs: {
                  builder: "object",
                  data: _vm.playersExportData,
                  filename: _vm.playerExportFilename,
                },
                on: { close: _vm.exportClose },
              }),
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "500px" },
              model: {
                value: _vm.editDialog,
                callback: function ($$v) {
                  _vm.editDialog = $$v
                },
                expression: "editDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    { class: ["primary", "lighten-1", "font-weight-bold"] },
                    [
                      _c("span", { staticClass: "text-h5" }, [
                        _vm._v("Edit Offer"),
                      ]),
                    ]
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c("offerDetailFormVue", {
                        ref: "offerDetailForm",
                        attrs: { disable: ["name", "code", "gamble"] },
                        model: {
                          value: _vm.detailCp,
                          callback: function ($$v) {
                            _vm.detailCp = $$v
                          },
                          expression: "detailCp",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "" },
                          on: {
                            click: function ($event) {
                              _vm.detailCp =
                                _vm.$refs.offerDetailForm.formData = {
                                  ..._vm.detail,
                                }
                              _vm.editDialog = false
                            },
                          },
                        },
                        [_vm._v(" Cancel ")]
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            text: "",
                            disabled: _vm.loading,
                          },
                          on: { click: _vm.submit },
                        },
                        [_vm._v(" Submit ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "630px" },
              on: {
                "click:outside": _vm.dialogClose,
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "esc", 27, $event.key, [
                      "Esc",
                      "Escape",
                    ])
                  )
                    return null
                  return _vm.dialogClose.apply(null, arguments)
                },
              },
              model: {
                value: _vm.ruleEditDialog,
                callback: function ($$v) {
                  _vm.ruleEditDialog = $$v
                },
                expression: "ruleEditDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    { class: ["primary", "lighten-1", "font-weight-bold"] },
                    [
                      _c("span", { staticClass: "text-h5" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.detail.offer_rules === null
                                ? "Create"
                                : "Edit"
                            ) +
                            " Offer Rule "
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c("RuleEdit", {
                        ref: "ruleEdit",
                        model: {
                          value: _vm.ruleCopy,
                          callback: function ($$v) {
                            _vm.ruleCopy = $$v
                          },
                          expression: "ruleCopy",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "" },
                          on: {
                            click: function ($event) {
                              return _vm.dialogClose()
                            },
                          },
                        },
                        [_vm._v(" Cancel ")]
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            text: "",
                            disabled: _vm.loading,
                          },
                          on: { click: _vm.submitRules },
                        },
                        [_vm._v(" Submit ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "500px" },
              on: {
                "click:outside": _vm.addPlayerOnClose,
                keydown: [
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "esc", 27, $event.key, [
                        "Esc",
                        "Escape",
                      ])
                    )
                      return null
                    return _vm.addPlayerOnClose.apply(null, arguments)
                  },
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.addPlayer.apply(null, arguments)
                  },
                ],
              },
              model: {
                value: _vm.addPlayersDialog,
                callback: function ($$v) {
                  _vm.addPlayersDialog = $$v
                },
                expression: "addPlayersDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    { class: ["primary", "lighten-1", "font-weight-bold"] },
                    [
                      _c("span", { staticClass: "text-h5" }, [
                        _vm._v("Add players"),
                      ]),
                    ]
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c("v-text-field", {
                        attrs: { autofocus: "", label: "Player ID" },
                        model: {
                          value: _vm.playerId,
                          callback: function ($$v) {
                            _vm.playerId = $$v
                          },
                          expression: "playerId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "" },
                          on: { click: _vm.addPlayerOnClose },
                        },
                        [_vm._v(" Close ")]
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            text: "",
                            disabled: _vm.playerId.length === 0,
                          },
                          on: { click: _vm.addPlayer },
                        },
                        [_vm._v(" Add Player ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "500px" },
              on: {
                "click:outside": _vm.addRoundsDialogOnClose,
                keydown: [
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "esc", 27, $event.key, [
                        "Esc",
                        "Escape",
                      ])
                    )
                      return null
                    return _vm.addRoundsDialogOnClose.apply(null, arguments)
                  },
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.addRoundsToPlayer.apply(null, arguments)
                  },
                ],
              },
              model: {
                value: _vm.addRoundsDialog,
                callback: function ($$v) {
                  _vm.addRoundsDialog = $$v
                },
                expression: "addRoundsDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    { class: ["primary", "lighten-1", "font-weight-bold"] },
                    [
                      _c("span", { staticClass: "text-h5" }, [
                        _vm._v("Add Rounds to player"),
                      ]),
                    ]
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c("v-text-field", {
                        attrs: {
                          autofocus: "",
                          label: "Rounds count",
                          type: "number",
                        },
                        model: {
                          value: _vm.spinCount,
                          callback: function ($$v) {
                            _vm.spinCount = _vm._n($$v)
                          },
                          expression: "spinCount",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "" },
                          on: { click: _vm.addRoundsDialogOnClose },
                        },
                        [_vm._v(" Close ")]
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", text: "" },
                          on: { click: _vm.addRoundsToPlayer },
                        },
                        [_vm._v(" Submit ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "500px" },
              model: {
                value: _vm.addGamesDialog,
                callback: function ($$v) {
                  _vm.addGamesDialog = $$v
                },
                expression: "addGamesDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    { class: ["primary", "lighten-1", "font-weight-bold"] },
                    [
                      _c("span", { staticClass: "text-h5" }, [
                        _vm._v("Add Games"),
                      ]),
                    ]
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c("offerGamesSelectVue", {
                        model: {
                          value: _vm.detailCp.games,
                          callback: function ($$v) {
                            _vm.$set(_vm.detailCp, "games", $$v)
                          },
                          expression: "detailCp.games",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "" },
                          on: {
                            click: function ($event) {
                              _vm.addGamesDialog = false
                            },
                          },
                        },
                        [_vm._v(" Close ")]
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", text: "" },
                          on: { click: _vm.modifyGames },
                        },
                        [_vm._v(" Submit ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-card"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }