<template>
	<v-tooltip
		:color="color"
		top
		v-bind="$attrs">
		<template #activator="{ on, attrs }">
			<div
				class="mr-1"
				style="border-radius: 50%"
				v-on="on"
				v-bind="attrs">
				<slot></slot>
			</div>
		</template>
		<span>{{ tooltip }}</span>
	</v-tooltip>
</template>

<script>
export default {
	props: {
		tooltip: {
			type: String,
			default: "",
		},
		color: {
			type: String,
			default: "primary",
		},
	},
};
</script>
