var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        {
          staticClass: "v-card--material px-5 py-3",
          attrs: { tile: _vm.tile, outlined: _vm.outlined, flat: _vm.flat },
        },
        [
          !_vm.loading
            ? [
                _vm._t(
                  "heading",
                  function () {
                    return [
                      _vm.title
                        ? _c("card-heading", {
                            attrs: { color: _vm.color, title: _vm.title },
                          })
                        : _vm._e(),
                    ]
                  },
                  { item: _vm.item }
                ),
                _vm.item !== null && _vm.item !== undefined
                  ? _c(
                      "span",
                      {
                        class: _vm.stretch,
                        staticStyle: {
                          "max-height": "80vh",
                          "overflow-y": "auto",
                        },
                      },
                      [
                        _vm._l(_vm.rows, function (row, index) {
                          return [
                            _c(
                              "v-row",
                              { key: `row${index}` },
                              [
                                _vm._l(row, function (col) {
                                  return [
                                    _c(
                                      "v-col",
                                      { key: `column-${col.key}` },
                                      [
                                        col.hide != true
                                          ? [
                                              _c(
                                                "v-list-item",
                                                [
                                                  col.label !== null &&
                                                  !col.fullsize
                                                    ? _c(
                                                        "v-list-item-content",
                                                        {
                                                          staticClass:
                                                            "text-body-1",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            [
                                                              _vm._t(
                                                                `item.${col.key}.label`,
                                                                function () {
                                                                  return [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          col.label
                                                                        ) +
                                                                        _vm._s(
                                                                          col.label
                                                                            ? ":"
                                                                            : ""
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                },
                                                                {
                                                                  item: _vm.item,
                                                                }
                                                              ),
                                                            ],
                                                            2
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "v-list-item-content",
                                                    {
                                                      staticClass:
                                                        "text-body-2",
                                                      class: `align-${
                                                        col.align ?? "end"
                                                      } text-${
                                                        col.align ?? "end"
                                                      }`,
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "align-center",
                                                        },
                                                        [
                                                          col.fullsize &&
                                                          col.label !== null
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "text-body-1 mr-5",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        col.label
                                                                      ) +
                                                                      ": "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _c(
                                                            "span",
                                                            [
                                                              _vm._t(
                                                                `item.${col.key}.value`,
                                                                function () {
                                                                  return [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          col.key
                                                                            .split(
                                                                              "."
                                                                            )
                                                                            .reduce(
                                                                              (
                                                                                prev,
                                                                                next
                                                                              ) =>
                                                                                prev[
                                                                                  next
                                                                                ] !==
                                                                                  null ||
                                                                                prev[
                                                                                  next
                                                                                ] !==
                                                                                  undefined
                                                                                  ? prev[
                                                                                      next
                                                                                    ]
                                                                                  : undefined,
                                                                              _vm.item
                                                                            )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                },
                                                                {
                                                                  item: _vm.item,
                                                                }
                                                              ),
                                                            ],
                                                            2
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                  ]
                                }),
                              ],
                              2
                            ),
                            index < _vm.rows.length - 1
                              ? _c("v-divider", { key: `divider${index}` })
                              : _vm._e(),
                          ]
                        }),
                      ],
                      2
                    )
                  : _c(
                      "v-card-text",
                      [
                        _vm._t("nodData", function () {
                          return [_vm._v("No data")]
                        }),
                      ],
                      2
                    ),
              ]
            : [_c("v-skeleton-loader", { attrs: { type: "card" } })],
          Object.keys(_vm.$slots)
            .join(Object.keys(_vm.$scopedSlots))
            .includes("actions")
            ? _c("v-card-actions", [_vm._t("actions")], 2)
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }