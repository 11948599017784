<template>
	<v-container
		id="Groups"
		fluid
		tag="section">
		<v-overlay
			z-index="60"
			v-model="loaderlay">
			<v-progress-circular
				indeterminate
				size="100"
				color="primary"
				class="l2">
				<v-progress-circular
					indeterminate
					size="75"
					color="primary"
					class="l3"></v-progress-circular>
			</v-progress-circular>
		</v-overlay>
		<v-row dense>
			<v-col cols="12">
				<KeyValueCard2
					:loading="loading"
					:item="detail"
					:cols="filteredDetailCols">
					<template v-slot:heading>
						<CardHeading :color="'secondary'">
							<template v-slot:title>
								<v-chip
									v-if="!detail.internal"
									class="mr-1">
									Casino
								</v-chip>
								{{ detailTitle }}
							</template>
							<span>
								<v-btn
									v-if="
										detail.internal &&
										_usrFlagsSome({
											key: 'free_games.detail',
											val: permissions.EDIT,
										})
									"
									elevation="1"
									fab
									small
									class="mx-1"
									:color="`primary`"
									@click="editDialog = true">
									<v-icon>mdi-pencil</v-icon>
								</v-btn>

								<v-dialog
									v-if="!detail.is_removed"
									v-model="dialogDisable"
									max-width="500px">
									<template v-slot:activator="{ on: dialog, attrs }">
										<v-tooltip
											color="error darken-2"
											bottom
											v-if="
												_usrFlagsSome({
													key: 'free_games.detail',
													val: permissions.DELETE,
												}) && !detail.is_removed
											">
											<template v-slot:activator="{ on: tooltip }">
												<span v-on="tooltip">
													<v-btn
														elevation="1"
														fab
														small
														class="mx-1"
														color="error"
														v-on="dialog"
														v-bind="attrs">
														<v-icon>mdi-delete</v-icon>
													</v-btn>
												</span>
											</template>
											<span>Delete</span>
										</v-tooltip>
									</template>
									<v-card>
										<v-toolbar class="red d-flex white--text font-weight-bold">
											Delete
										</v-toolbar>
										<v-card-text>
											Are you sure you want to delete this Offer ?
										</v-card-text>
										<v-card-actions class="justify-space-between">
											<v-btn
												text
												@click="closeDisable">
												NO
											</v-btn>

											<v-btn
												color="error"
												text
												@click="handleDisableOffer">
												YES
											</v-btn>
											<template></template>
										</v-card-actions>
									</v-card>
								</v-dialog>

								<v-dialog
									v-if="detail.is_removed"
									v-model="dialogReactivate"
									max-width="500px">
									<template v-slot:activator="{ on: dialog, attrs }">
										<v-tooltip
											color="primary darken-2"
											bottom
											v-if="
												_usrFlagsSome({
													key: 'free_games.detail',
													val: permissions.DELETE,
												}) && detail.is_removed
											">
											<template v-slot:activator="{ on: tooltip }">
												<span v-on="tooltip">
													<v-btn
														elevation="1"
														fab
														small
														class="mx-1"
														color="red darken-1"
														v-on="dialog"
														v-bind="attrs">
														<v-icon style="padding-right: 2px">
															mdi-restore
														</v-icon>
													</v-btn>
												</span>
											</template>
											<span>Restore</span>
										</v-tooltip>
									</template>

									<v-card>
										<v-toolbar class="red d-flex white--text font-weight-bold">
											Restore
										</v-toolbar>
										<v-card-text>
											Are you sure you want to restore this Offer?
										</v-card-text>
										<v-card-actions class="justify-space-between">
											<v-btn
												text
												@click="closeReactivate">
												NO
											</v-btn>

											<v-btn
												color="error"
												text
												@click="handleReactivateOffer">
												YES
											</v-btn>
										</v-card-actions>
									</v-card>
								</v-dialog>
							</span>
						</CardHeading>
					</template>
					<template v-slot:[`item.gamble_enabled.value`]="{ item }">
						<v-icon
							v-if="item.gamble_enabled"
							color="success">
							mdi-check
						</v-icon>
						<v-icon
							v-else
							color="error">
							mdi-close
						</v-icon>
					</template>
					<template v-slot:[`item.valid_from.value`]="{ item }">
						{{ item.valid_from | Date }}
					</template>
					<template v-slot:[`item.spacer.label`]>{{ "" }}</template>
					<template v-slot:[`item.valid_to.value`]="{ item }">
						{{ item.valid_to | Date }}
					</template>
					<template v-slot:[`item.status.value`]="{ item }">
						<v-chip
							small
							v-if="item.is_removed"
							color="error">
							Closed
						</v-chip>
						<v-chip
							small
							color="warning"
							v-else-if="new Date(item.valid_from).getTime() > timeNow">
							Pre-Activation
						</v-chip>
						<v-chip
							small
							color="warning"
							v-else-if="new Date(item.valid_to).getTime() < timeNow">
							Expired
						</v-chip>
						<v-chip
							small
							v-else
							color="success">
							Active
						</v-chip>
					</template>
				</KeyValueCard2>
			</v-col>
			<v-col
				v-if="detail.internal && getEnvironment === 'dev'"
				cols="12"
				md="6">
				<KeyValueCard2
					:loading="loadingRule"
					:item="detail.offer_rules"
					:cols="filteredRuleCols">
					<template v-slot:nodData>
						{{
							detail.is_removed
								? "Cannot create rules for deleted offer."
								: "This offer doesn't have an assigned rule"
						}}
					</template>
					<template v-slot:heading>
						<CardHeading
							title="Offer Rule"
							:color="'secondary'">
							<div class="d-inline-flex">
								<v-tooltip
									:key="`${rule_valid_from}-tooltip`"
									v-if="detail.offer_rules === null && !detail.is_removed"
									color="warning darken-2"
									bottom>
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											v-on="on"
											v-bind="attrs"
											v-if="
												_usrFlagsSome({
													key: 'free_games.detail',
													val: permissions.EDIT,
												})
											"
											elevation="1"
											fab
											small
											class="mx-1"
											:color="'warning'"
											@click="openRuleEdit">
											<v-icon v-if="detail.offer_rules === null">
												mdi-plus
											</v-icon>
										</v-btn>
									</template>
									<span>Add a rule</span>
								</v-tooltip>

								<template v-else>
									<v-btn
										v-if="
											_usrFlagsSome({
												key: 'free_games.detail',
												val: permissions.EDIT,
											}) && !detail.is_removed
										"
										elevation="1"
										fab
										small
										class="mx-1"
										:color="'primary'"
										@click="ruleEditDialog = true">
										<v-icon>mdi-pencil</v-icon>
									</v-btn>
								</template>
								<v-dialog
									v-if="detail.offer_rules"
									v-model="dialogDelete"
									max-width="500px">
									<template v-slot:activator="{ on: dialog, attrs }">
										<v-tooltip
											color="error darken-2"
											bottom
											v-if="detail.offer_rules">
											<template v-slot:activator="{ on: tooltip }">
												<span v-on="tooltip">
													<v-btn
														elevation="1"
														fab
														small
														class="mx-1"
														color="error"
														v-on="dialog"
														v-bind="attrs">
														<v-icon>mdi-delete</v-icon>
													</v-btn>
												</span>
											</template>
											<span>Delete</span>
										</v-tooltip>
									</template>
									<v-card>
										<v-toolbar class="red d-flex white--text font-weight-bold">
											Delete
										</v-toolbar>
										<v-card-text>
											Are you sure you want to delete this Rule ?
										</v-card-text>
										<v-card-actions class="justify-space-between">
											<v-btn
												text
												@click="closeRemove">
												NO
											</v-btn>

											<v-btn
												color="error"
												text
												@click="handleRemoveRule">
												YES
											</v-btn>
										</v-card-actions>
									</v-card>
								</v-dialog>
							</div>
						</CardHeading>
					</template>
					<template v-slot:[`item.mode.which_days.value`]="{ item }">
						{{ item.mode.which_days.join(", ") }}
					</template>
					<template v-slot:[`item.mode.type.value`]="{ item }">
						<span v-if="item.mode.type === 'recurring'">Recurring</span>
						<span v-else-if="item.mode.type === 'one_time'">One time</span>
					</template>
					<template v-slot:[`item.condition.action.value`]="{ item }">
						<span v-if="item.condition.action === OFFER_RULE_ACTIONS.ROUNDS">
							Rounds Started
						</span>
						<span v-else-if="item.condition.action === OFFER_RULE_ACTIONS.BET">
							Bet
						</span>
						<span
							v-else-if="item.condition.action === OFFER_RULE_ACTIONS.SESSIONS">
							Sessions Opened
						</span>
						<span
							v-else-if="item.condition.action === OFFER_RULE_ACTIONS.LOOSE">
							Lost
						</span>
					</template>
					<template v-slot:[`item.condition.limit.label`]="{ item }">
						<span
							v-if="
								item.condition.action === OFFER_RULE_ACTIONS.BET ||
								item.condition.action === OFFER_RULE_ACTIONS.LOOSE
							">
							Amount
						</span>
						<span v-else>Count</span>
					</template>
					<template v-slot:[`item.condition.limit.value`]="{ item }">
						<span
							v-if="
								item.condition.action === OFFER_RULE_ACTIONS.BET ||
								item.condition.action === OFFER_RULE_ACTIONS.LOOSE
							">
							{{ item.condition.limit }} {{ item.condition.currency }}
						</span>
						<span v-else>{{ item.condition.limit }}</span>
					</template>
					<template v-slot:[`item.hide_from_player.value`]="{ item }">
						<v-icon
							v-if="!item.hide_from_player"
							color="success">
							mdi-check
						</v-icon>
						<v-icon
							v-else
							color="error">
							mdi-close
						</v-icon>
					</template>
				</KeyValueCard2>
			</v-col>
			<v-col
				v-if="detail.internal && getEnvironment === 'dev'"
				cols="12"
				md="6">
				<v-card class="v-card--material px-5 py-3">
					<CardHeading
						:loadingTitle="'Loading Black/White List'"
						:loading="loadingList"
						:title="listMode"
						:color="'secondary'">
						<span>
							<v-btn
								v-if="
									_usrFlagsSome({
										key: 'free_games.detail',
										val: permissions.EDIT,
									})
								"
								elevation="1"
								fab
								small
								class="mx-1"
								:color="`primary`"
								:to="{
									name: 'free-games-blackwhitelist',
									params: {
										breadcrumbs: {
											title: 'List View',
										},
									},
								}">
								<v-icon>mdi-eye</v-icon>
							</v-btn>
						</span>
					</CardHeading>
					<v-card-text>
						<span v-if="listMode == 'No List'">
							This offer doesn't have a permissions list
						</span>
						<v-data-table
							v-else
							:headers="listHeaders"
							:items="aggregatedData"
							:loading="loadingList"
							dense
							class="elevation-1"
							hide-default-header
							:footer-props="{ 'items-per-page-options': [6] }">
							<template
								v-for="i in itemsPerRow"
								v-slot:[`item.item${i}`]="{ item }">
								{{ item[`item${i}`] }}
							</template>
						</v-data-table>
					</v-card-text>
				</v-card>
			</v-col>

			<v-col
				cols="12"
				md="6">
				<v-card class="v-card--material px-5 py-3">
					<card-heading
						color="secondary"
						title="Games">
						<v-btn
							v-if="
								detail.internal &&
								_usrFlagsSome({
									key: 'free_games.detail',
									val: permissions.EDIT,
								})
							"
							elevation="1"
							fab
							small
							color="primary"
							@click="openAddGames">
							<v-icon>mdi-pencil</v-icon>
						</v-btn>
					</card-heading>
					<v-data-table
						must-sort
						sort-by="game_id"
						:sort-desc="false"
						:headers="filteredGameHeaders"
						:items="gameList"
						:options.sync="gameOptions"
						:loading="loading"
						:footer-props="footerProps"
						class="elevation-0">
						<template v-slot:[`item.bet`]="{ item }">
							{{ item.configuration.bet }}
						</template>
						<template v-slot:[`item.currency`]="{ item }">
							{{ item.configuration.currency }}
						</template>

						<template v-slot:[`item.actions`]="{ item }">
							<v-tooltip
								:v-if="
									_usrFlagsSome({
										key: 'free_games.detail',
										val: permissions.EDIT,
									})
								"
								bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-hover v-slot="{ hover }">
										<span @click.stop.prevent="removeGame(item)">
											<v-icon
												v-bind="attrs"
												v-on="on"
												:color="hover ? 'red' : undefined">
												mdi-close
											</v-icon>
										</span>
									</v-hover>
								</template>
								<span>Remove game from offer</span>
							</v-tooltip>
						</template>
					</v-data-table>
				</v-card>
			</v-col>
			<v-col
				cols="12"
				md="6">
				<v-card class="v-card--material px-5 py-3">
					<card-heading
						color="secondary"
						title="Players">
						<!-- PLAYERS -->
						<div class="d-flex flex-row">
							<SimpleTooltip :tooltip="`Import players`">
								<v-btn
									v-if="
										!loading &&
										detail.internal &&
										!detail.is_removed &&
										_usrFlagsSome({
											key: 'free_games.detail',
											val: permissions.EDIT,
										})
									"
									elevation="1"
									fab
									small
									color="primary"
									@click="importDialog = true">
									<v-icon>mdi-arrow-up-bold-circle</v-icon>
								</v-btn>
							</SimpleTooltip>

							<SimpleTooltip
								:tooltip="`${
									players?.length === 0
										? 'No players to export'
										: 'Export players'
								}`">
								<v-btn
									v-if="!loading"
									:disabled="players?.length == 0"
									elevation="1"
									fab
									small
									color="primary"
									@click="onExportOpen">
									<v-icon>mdi-arrow-down-bold-circle</v-icon>
								</v-btn>
							</SimpleTooltip>

							<SimpleTooltip :tooltip="`Add players`">
								<v-btn
									v-if="
										!loading &&
										detail.internal &&
										!detail.is_removed &&
										_usrFlagsSome({
											key: 'free_games.detail',
											val: permissions.EDIT,
										})
									"
									elevation="1"
									fab
									small
									color="primary"
									@click="addPlayersDialog = true">
									<v-icon>mdi-plus</v-icon>
								</v-btn>
							</SimpleTooltip>
							<ImportDialog
								parser="checkedParser"
								@submit="onImport"
								:importDialog.sync="importDialog">
								<template #hint-body>
									<div class="my-1">
										This import utilizes the following data format:
									</div>

									<i><b>Players.csv</b></i>
									<v-card class="mt-1">
										<v-card-text>
											<table>
												<tr>
													<td><b>player_id</b></td>
												</tr>
												<tr
													:key="i"
													v-for="i in 10">
													<td>
														<b>{{ i }}</b>
													</td>
												</tr>
												<tr><td>...</td></tr>
											</table>
										</v-card-text>
									</v-card>
									<i>
										Line separated list of player IDs with "player_id" header
									</i>
								</template>
							</ImportDialog>
						</div>
					</card-heading>
					<v-data-table
						must-sort
						sort-by="game_id"
						:sort-desc="false"
						:headers="filteredPlayerHeaders"
						:items="players"
						:item-class="itemClass"
						:options.sync="playerOptions"
						:loading="playersLoading"
						:footer-props="{
							'items-per-page-options': [5, 10, 15, 25, 50, 100],
						}"
						class="elevation-0">
						<template
							v-if="
								this._usrFlagsSome({
									key: 'player.detail',
									val: permissions.READ,
								})
							"
							v-slot:[`item.player_id`]="{ item }">
							<span
								class="hoverTheme body-2 font-weight-bold primary--text pa-1"
								@click.prevent="toPlayer(item)">
								{{ item.player_id }}
							</span>
						</template>
						<template v-slot:[`item.is_removed`]="{ item }">
							<v-icon
								v-if="item.is_removed"
								color="error">
								mdi-check
							</v-icon>
						</template>
						<template v-slot:[`item.actions`]="{ item }">
							<v-tooltip
								v-if="!item.is_removed"
								color="primary darken-2"
								bottom>
								<template v-slot:activator="{ on, attrs }">
									<span @click="openAddRounds(item)">
										<v-icon
											v-bind="attrs"
											v-on="on"
											color="primary">
											mdi-plus
										</v-icon>
									</span>
								</template>
								<span>Add free rounds to player</span>
							</v-tooltip>
							<v-tooltip
								v-if="!item.is_removed"
								color="primary darken-2"
								bottom>
								<template v-slot:activator="{ on, attrs }">
									<ConfirmDialog2
										persistent
										:confirmBtnText="'Delete'"
										:cancelBtnText="'Back'"
										:confirmBtnColor="'error'"
										:titleClass="'error'"
										:cancelBtnColor="'kajot-text'"
										:shouldShow="true"
										@confirm="removePlayer(item)">
										<template v-slot:body>
											This player will be permanently deleted from the offer.
											<br />
											<b>Do you want to proceed?</b>
										</template>
										<template v-slot:default="{ showConfirm }">
											<span @click="showConfirm">
												<v-icon
													v-bind="attrs"
													v-on="on"
													color="primary">
													mdi-delete
												</v-icon>
											</span>
										</template>
									</ConfirmDialog2>
								</template>
								<span>Remove player from offer</span>
							</v-tooltip>
						</template>
					</v-data-table>
				</v-card>
			</v-col>

			<v-dialog
				@click:outside="exportClose"
				@keydown.esc="exportClose"
				max-width="500px"
				v-model="exportDialog">
				<ExportDialog
					@close="exportClose"
					builder="object"
					:data="playersExportData"
					:filename="playerExportFilename"
					ref="playerExport"></ExportDialog>
			</v-dialog>

			<!-- DETAIL EDIT -->
			<v-dialog
				v-model="editDialog"
				max-width="500px">
				<v-card>
					<v-card-title :class="['primary', 'lighten-1', 'font-weight-bold']">
						<span class="text-h5">Edit Offer</span>
					</v-card-title>

					<v-card-text>
						<offerDetailFormVue
							ref="offerDetailForm"
							v-model="detailCp"
							:disable="['name', 'code', 'gamble']"></offerDetailFormVue>
					</v-card-text>
					<v-card-actions>
						<v-btn
							text
							@click="
								detailCp = $refs.offerDetailForm.formData = { ...detail };
								editDialog = false;
							">
							Cancel
						</v-btn>
						<v-spacer />
						<v-btn
							color="primary"
							text
							@click="submit"
							:disabled="loading">
							Submit
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
			<!-- RULE EDIT -->
			<v-dialog
				v-model="ruleEditDialog"
				max-width="630px"
				@click:outside="dialogClose"
				@keydown.esc="dialogClose">
				<v-card>
					<v-card-title :class="['primary', 'lighten-1', 'font-weight-bold']">
						<span class="text-h5">
							{{ detail.offer_rules === null ? "Create" : "Edit" }} Offer Rule
						</span>
					</v-card-title>

					<v-card-text>
						<RuleEdit
							ref="ruleEdit"
							v-model="ruleCopy"></RuleEdit>
					</v-card-text>
					<v-card-actions>
						<v-btn
							text
							@click="dialogClose()">
							Cancel
						</v-btn>
						<v-spacer />
						<v-btn
							color="primary"
							@click="submitRules"
							text
							:disabled="loading">
							Submit
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
			<!-- PLAYERS EDIT -->
			<v-dialog
				@click:outside="addPlayerOnClose"
				@keydown.esc="addPlayerOnClose"
				@keydown.enter="addPlayer"
				v-model="addPlayersDialog"
				max-width="500px">
				<v-card>
					<v-card-title :class="['primary', 'lighten-1', 'font-weight-bold']">
						<span class="text-h5">Add players</span>
					</v-card-title>

					<v-card-text>
						<v-text-field
							autofocus
							label="Player ID"
							v-model="playerId"></v-text-field>
					</v-card-text>
					<v-card-actions>
						<v-btn
							text
							@click="addPlayerOnClose">
							Close
						</v-btn>
						<v-spacer />
						<v-btn
							color="primary"
							text
							@click="addPlayer"
							:disabled="playerId.length === 0">
							Add Player
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
			<!-- ROUNDS EDIT -->
			<v-dialog
				@click:outside="addRoundsDialogOnClose"
				@keydown.esc="addRoundsDialogOnClose"
				@keydown.enter="addRoundsToPlayer"
				v-model="addRoundsDialog"
				max-width="500px">
				<v-card>
					<v-card-title :class="['primary', 'lighten-1', 'font-weight-bold']">
						<span class="text-h5">Add Rounds to player</span>
					</v-card-title>

					<v-card-text>
						<v-text-field
							autofocus
							label="Rounds count"
							type="number"
							v-model.number="spinCount"></v-text-field>
					</v-card-text>
					<v-card-actions>
						<v-btn
							text
							@click="addRoundsDialogOnClose">
							Close
						</v-btn>
						<v-spacer />
						<v-btn
							color="primary"
							text
							@click="addRoundsToPlayer">
							Submit
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
			<!-- GAME EDIT -->
			<v-dialog
				v-model="addGamesDialog"
				max-width="500px">
				<v-card>
					<v-card-title :class="['primary', 'lighten-1', 'font-weight-bold']">
						<span class="text-h5">Add Games</span>
					</v-card-title>

					<v-card-text>
						<!-- <v-text-field label="Player ID" v-model="playerId"></v-text-field> -->
						<offerGamesSelectVue v-model="detailCp.games"></offerGamesSelectVue>
					</v-card-text>
					<v-card-actions>
						<v-btn
							text
							@click="addGamesDialog = false">
							Close
						</v-btn>
						<v-spacer />
						<v-btn
							color="primary"
							text
							@click="modifyGames">
							Submit
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
			<v-card></v-card>
		</v-row>
	</v-container>
</template>
<script>
import { format } from "date-fns";
import _ from "lodash";
import { mapActions, mapGetters, mapMutations } from "vuex";
import offerDetailFormVue from "../../components/freeGames/offerDetailForm.vue";
import offerGamesSelectVue from "../../components/freeGames/offerGamesSelect.vue";
import CardHeading from "../../components/shared/CardHeading.vue";
import ConfirmDialog2 from "../../components/shared/ConfirmDialog2.vue";
import ExportDialog from "../../components/shared/ExportDialog.vue";
import ImportDialog from "../../components/shared/ImportDialog.vue";
import KeyValueCard2 from "../../components/shared/keyValueCard2.vue";
import SimpleTooltip from "../../components/shared/SimpleTooltip.vue";
import { FEATURE_FLAGS, OFFER_RULE_ACTIONS } from "../../constants/constants";
import permissions from "../../mixins/permissions";
import table from "../../mixins/table";
import RuleEdit from "./RuleEdit.vue";

export default {
	mixins: [permissions, table],
	components: {
		KeyValueCard2,
		CardHeading,
		offerDetailFormVue,
		offerGamesSelectVue,
		RuleEdit,
		ExportDialog,
		ConfirmDialog2,
		ImportDialog,
		SimpleTooltip,
	},
	data() {
		return {
			timeNow: Date.now(),
			loaderlay: false,
			importDialog: false,
			exportDialog: false,
			loadingRule: false,
			loadingList: false,
			rule: null,
			ruleCols: [
				[{ label: "Type", key: "mode.type" }],
				{
					items: [{ label: "Days", key: "mode.which_days" }],
					hide: (detail) => !detail?.mode?.which_days,
				},
				[
					{ label: "From", key: "mode.valid_from_within_day" },
					{ label: "To", key: "mode.valid_to_within_day" },
				],
				[
					{ label: "Action", key: "condition.action" },
					{ label: "amount", key: "condition.limit" },
				],
				[{ label: "Show to player", key: "hide_from_player" }],

				{
					items: [{ label: "Description", key: "description" }],
					hide: (detail) => detail?.hide_from_player,
				},
			],
			menu: false,
			addGamesDialog: false,
			addRoundsDialog: false,
			spinCount: 1,

			// activePicker: null,
			playersLoading: false,
			OFFER_RULE_ACTIONS,
			editDialog: false,
			ruleEditDialog: false,
			addPlayersDialog: false,
			listMode: "",
			playerId: "",
			loading: false,
			offer_code: this.$route.params.offer_id,
			dialogDisable: false,
			dialogReactivate: false,
			dialogDelete: false,

			detailRows: [
				[
					{ label: "Status", key: "status" },
					{ label: "", key: "empty" },
				],
				[
					{ label: "Name", key: "name" },
					{ label: "Code", key: "offer_code" },
				],
				[
					{ label: "From", key: "valid_from" },
					{ label: "To", key: "valid_to" },
				],
				{
					items: [
						{ label: "Spins", key: "spins" },
						{ key: "spacer", label: "" },
					],
					hide: () => FEATURE_FLAGS.FREE_GAMES_GAMBLE_SUPPORT,
				},
				{
					items: [
						{ label: "Spins", key: "spins" },
						{ label: "Gamble", key: "gamble_enabled" },
					],
					hide: () => !FEATURE_FLAGS.FREE_GAMES_GAMBLE_SUPPORT,
				},
			],

			gameHeaders: [
				{ text: "Game ID", value: "game_id", align: "center" },
				{ text: "Bet", value: "bet", align: "center" },
				{ text: "Currency", value: "currency", align: "center" },
				{
					text: "Actions",
					value: "actions",
					align: "center",
					hide: () =>
						!this._usrFlagsSome({
							key: "free_games.detail",
							val: this.permissions.EDIT,
						}),
				},
			],
			playerHeaders: [
				{ text: "Player ID", value: "player_id", align: "center" },
				{ text: "Σ Win", value: "total_win", align: "center" },
				{ text: "Spins left", value: "spins_left", align: "center" },
				{
					text: "Deleted",
					value: "is_removed",
					align: "center",
					sortable: false,
					hide: (detail) => this.players.every((el) => !el.is_removed),
				},
				{
					text: "Actions",
					value: "actions",
					align: "center",
					sortable: false,
					hide: (detail) =>
						detail.is_removed ||
						!this._usrFlagsSome({
							key: "free_games.detail",
							val: this.permissions.EDIT,
						}),
				},
			],
			gameOptions: {},
			playerOptions: {},
			detailCp: {},
			ruleCopy: {},
			listCopy: {},
			bwList: null,
			// games
		};
	},
	computed: {
		...mapGetters(["currentCasino", "getEnvironment"]),
		...mapGetters("breadcrumbs", {
			shortCrumbs: "shortCrumbsFromHistory",
		}),
		filteredGameHeaders() {
			return this.gameHeaders.filter((el) => !el.hide?.());
		},
		filteredPlayerHeaders() {
			return this.playerHeaders.filter((el) => !el.hide?.(this.detail));
		},
		rule_valid_from() {
			return this.detail?.offer_rules?.valid_from ?? "no-rule";
		},
		playersExportData() {
			return [["player_id", "total_win", "spins_left"], this.players];
		},
		playerExportFilename() {
			const today = new Date();
			return `${this.detail.offer_code}-players-${today.getDate()}_${
				today.getMonth() + 1
			}_${today.getFullYear()}`;
		},
		itemsPerRow() {
			switch (this.$vuetify.breakpoint.name) {
				case "xs":
					return 1;
				case "sm":
					return 2;
				case "md":
					return 1;
				case "lg":
					return 3;
			}
			return this.$vuetify.breakpoint.width > 1800 ? 3 : 2;
		},
		...mapGetters("breadcrumbs", {
			lastCrumb: "lastCrumb",
		}),
		filteredDetailCols() {
			return this.detailRows
				.filter((el) => {
					const res = !el.hide?.();
					return res;
				})
				.map((el) => (Array.isArray(el) ? el : el.items));
		},
		...mapGetters("freeGames", {
			detail: "detail",
			players: "players",
		}),
		aggregatedData() {
			const res = [];
			if (this.bwList == null) return res;
			for (let i = 0; i < this.bwList.length; i += this.itemsPerRow) {
				const item = {};
				for (const i2 of [...Array(this.itemsPerRow).keys()]) {
					item[`item${i2 + 1}`] = this.bwList[i + i2] ?? "";
				}
				res.push(item);
			}
			return res;
		},
		detailTitle() {
			return `Offer: ${this.detail.name}`;
		},
		date() {
			return format(new Date(this.detail.valid_from), "dd-MM-yyyy");
		},
		gameList() {
			return this.detail.games ?? [];
		},
		listHeaders() {
			return new Array(this.itemsPerRow).fill().map((_, i) => {
				return {
					text: "Id",
					value: `item${i + 1}`,
					align: "center",
				};
			});
		},
		filteredRuleCols() {
			return this.ruleCols
				.filter((el) => {
					const res = !el.hide?.(this.detail?.offer_rules);
					return res;
				})
				.map((el) => (Array.isArray(el) ? el : el.items));
		},
	},
	methods: {
		...mapMutations("globalTicker", {
			sub: "subscribe",
			unsub: "unsubscribe",
		}),
		timeSetter() {
			this.timeNow = Date.now();
		},
		onImport({ data }) {
			let i = 0;
			const players = [];
			for (const [id] of data) {
				if (id === "") {
					continue;
				}
				if (this.players.some((el) => el.player_id === id)) {
					continue;
				}
				players.push(id);
			}
			this.addPlayersToOffer({
				offer_code: this.detail.offer_code,
				players,
			});
		},
		async toPlayer(item) {
			this.loaderlay = true;

			try {
				await this.$router.push({
					name: "player-detail",
					params: {
						player_id: `${item.player_id}`,
						breadcrumbs: { title: `Player ${item.player_id}` },
						casino: this.currentCasino,
					},
				});
			} catch (error) {
				this.loaderlay = false;
			}
		},
		exportClose(e) {
			this.exportDialog = false;
			this.$refs.playerExport.onClose();
		},
		itemClass(el) {
			return el.is_removed === true ? "error--text" : "";
		},
		onExportOpen() {
			this.exportDialog = true;
			//v-dialog internally uses v-if directive, we need to wait for a bit for ref to be registered
			setTimeout(() => {
				this.$refs.playerExport.onExportOpen();
			}, 500);
		},
		...mapActions("freeGames", {
			loadDetail: "loadDetail",
			loadBWList: "loadBWList",
			loadPlayers: "loadPlayersPerOffer",
			updateOffer: "updateOffer",
			createRule: "createRule",
			removeRule: "removeRule",
			updateRule: "updateRule",
			addPlayerToOffer: "addPlayerToOffer",
			addPlayersToOffer: "addPlayersToOffer",
			removePlayerFromOffer: "removePlayerFromOffer",
			addRounds: "addRoundsToPlayer",
			updateGames: "updateGames",
		}),
		...mapMutations("freeGames", {
			setPlayers: "players",
			setDetail: "detail",
		}),
		...mapActions("notification", {
			setMessage: "setMessage",
		}),
		closeReactivate() {
			this.dialogReactivate = false;
		},
		closeRemove() {
			this.dialogDelete = false;
		},
		closeDisable() {
			this.dialogDisable = false;
		},
		navBack() {
			this.$router.push;
			this.lastCrumb.link;
		},
		openRuleEdit() {
			this.ruleEditDialog = true;
			this.ruleCopy = { ...this.detail.offer_rules };
		},
		dialogClose() {
			this.ruleEditDialog = false;
			this.$refs.ruleEdit.reset();
			this.ruleCopy = JSON.parse(JSON.stringify(this.detail.offer_rules));
			// this.load();
		},
		addPlayerOnClose() {
			this.addPlayersDialog = false;
			this.playerId = "";
		},
		async addPlayer() {
			await this.addPlayerToOffer({
				offer_code: this.offer_code,
				player_id: this.playerId,
			});
			this.addPlayersDialog = false;
			this.loadPlayerList();
			this.playerId = "";
		},
		openAddGames() {
			this.addGamesDialog = true;
		},
		async modifyGames(game) {
			await this.updateGames({
				offer_code: this.offer_code,
				modified_games: this.detailCp.games,
			});
			this.load();
			this.addGamesDialog = false;
		},
		async removeGame(item) {
			await this.updateGames({
				offer_code: this.offer_code,
				modified_games: this.detailCp.games.filter(
					(el) => !_.isEqual(el, item)
				),
			});
			this.load();
		},
		async removePlayer(player) {
			this.playersLoading = true;
			await this.removePlayerFromOffer({
				offer_code: this.offer_code,
				player_id: player.player_id,
			});
			this.loadPlayerList();
		},
		openAddRounds(player) {
			this.playerId = player.player_id;
			this.addRoundsDialog = true;
		},
		addRoundsDialogOnClose() {
			this.addRoundsDialog = false;
			this.spinCount = 1;
		},
		async addRoundsToPlayer() {
			await this.addRounds({
				offer_code: this.offer_code,
				player_id: this.playerId,
				spins_to_add: this.spinCount,
			});
			await this.loadPlayerList();
			this.playerId = "";
			this.addRoundsDialog = false;
			this.addRoundsDialogOnClose();
		},
		async handleDisableOffer() {
			this.closeDisable();
			await this.updateOffer({
				offer_code: this.detail.offer_code,
				is_removed: true,
			});
			this.load();
		},

		async handleReactivateOffer() {
			this.closeReactivate();
			await this.updateOffer({
				offer_code: this.detail.offer_code,
				is_removed: false,
			});
			this.load();
		},
		async submit() {
			await this.updateOffer(this.detailCp);
			this.editDialog = false;
			this.load();
		},
		async handleRemoveRule() {
			this.closeRemove();
			await this.removeRule({
				offerCode: this.detail.offer_code,
			});
			this.load();
		},
		async submitRules() {
			function removeNullAndUndefined(val) {
				for (const key in val) {
					if (val[key] === null || val[key] === undefined) {
						delete val[key];
					}
					if (typeof val[key] === "object") {
						removeNullAndUndefined(val[key]);
					}
				}
			}
			if ((await this.$refs.ruleEdit.submitHandler()) === false) return;
			if (
				this.detail.offer_rules === null ||
				this.detail.offer_rules === undefined
			) {
				await this.createRule({
					rule: this.ruleCopy,
					offerCode: this.detail.offer_code,
				});
			} else {
				removeNullAndUndefined(this.ruleCopy);
				await this.updateRule({
					rule: this.ruleCopy,
					offerCode: this.detail.offer_code,
				});
			}
			this.dialogClose();
			this.load();
		},
		async load() {
			this.loading = true;
			this.listLoading = true;
			await this.loadDetail({ offer_code: this.offer_code });
			this.detailCp = JSON.parse(JSON.stringify(this.detail));
			this.ruleCopy = JSON.parse(JSON.stringify(this.detail.offer_rules));
			const bwListData = await this.loadBWList({ offerCode: this.offer_code });
			this.bwList = bwListData.players;
			switch (bwListData.list_type) {
				case -1:
					this.listMode = "No List";
					break;
				case 1:
					this.listMode = "Blacklist";
					break;
				case 2:
					this.listMode = "Whitelist";
			}
			this.detailCp.games = this.detailCp.games ?? [];
			this.loading = false;
			this.listLoading = false;
		},
		async loadPlayerList() {
			this.playersLoading = true;
			await this.loadPlayers(this.offer_code);
			this.playersLoading = false;
		},
	},
	mounted() {
		this.sub(this.timeSetter);
	},
	beforeDestroy() {
		this.unsub(this.timeSetter);
	},
	created() {
		this.setPlayers([]);
		this.setDetail({});
		this.load();
		this.loadPlayerList();
	},
};
</script>

<style scoped lang="sass">
.hoverTheme:hover
	background-color: var(--v-primary-base)
	border-radius: 10px
	color: var(--v-kajot-text-base) !important
	-webkit-user-select: none
	-ms-user-select: none
	user-select: none
	cursor: pointer

::v-deep
	@for $i from 1 through 3
		& .l#{$i}>svg
			$dur:4s
			$offset: .3
			$delay: ($i * $offset) - 3s
			animation-duration: $dur
			animation-delay: $delay
			animation-direction: alternate
			animation-timing-function: ease-in-out
			@if $i % 2 == 0
				animation-direction: alternate-reverse
			.v-progress-circular__overlay
				animation-duration: $dur
				animation-delay: $delay
				animation-direction: alternate
				animation-timing-function: ease-in-out
				@if $i % 2 == 0
					animation-direction: alternate-reverse
</style>
